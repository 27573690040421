import { jsx as _jsx } from "react/jsx-runtime";
import { ProductFruits } from 'react-product-fruits';
import { useUser } from '../hooks/useUser';
export function ProductFruitsWidget() {
    var _a, _b;
    var user = useUser();
    console.log("Product Fruits Widget rendered");
    return (_jsx(ProductFruits, { workspaceCode: "7L9WvBk3GxJnFQN2", language: "de", lifeCycle: "neverUnmount", user: {
            username: user ? "".concat(user.firstName).concat(user.lastName) : 'Unknown User',
            firstname: (_a = user === null || user === void 0 ? void 0 : user.firstName) !== null && _a !== void 0 ? _a : 'Unknown User',
            email: (_b = user === null || user === void 0 ? void 0 : user.email) !== null && _b !== void 0 ? _b : 'Unknown User',
        } }));
}
